.slick-slider {
  overflow: hidden;
}

.carousel-button::before {
  display: none;
}

.carousel-button:hover,
.carousel-button:focus,
.carousel-button:hover,
.carousel-button:focus {
  background-color: #001e45;
}
