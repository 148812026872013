.switch {
  @apply relative inline-block bg-gray-400 rounded-full cursor-pointer;
  width : 40px;
  height : 21px;
  &::after {
    @apply absolute rounded-full bg-white;
    content: '';
    width: 17px;
    height: 17px;
    top: 2px;
    left: 2px;
    transition: all 0.3s ease;
  }
}

.checkbox {
  display: none;
  &:checked {
    + .switch {
      @apply bg-black;
    }
    + .switch::after {
      left : 21px;
    }
  }
}
