.mobile-menu {
  transform: translateX(-100%);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  &--active {
    transform: translateX(0);
  }
  &-is-active {
    @apply overflow-y-hidden h-full relative;
  }
}

.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  cursor: pointer;
}
.menu-trigger {
  position: relative;
  width: 30px;
  height: 23px;
}
.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 2px;
}
.menu-trigger span:nth-of-type(1) {
  top: 0;
}
.menu-trigger span:nth-of-type(2) {
  top: 10px;
}
.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(10px) rotate(-45deg);
  transform: translateY(10px) rotate(-45deg);
}
.menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}
.menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-10px) rotate(45deg);
  transform: translateY(-10px) rotate(45deg);
}
