.filters-button-is-active {
    svg {
      stroke: white;
    }
}

.filters {
  max-height: 0;
  overflow: hidden;
  transition: all 600ms ease;
  &-is-active {
    max-height: 300px;
    height: auto;
  }
}
