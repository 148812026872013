.ReactModal__Overlay {
  z-index: 99999;
}

.ReactModal__Content {
  position: relative !important;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
}
