.hero-box {
  &-small {
    height: 450px;
    @media only screen and (max-width: 767px) {
      height: 250px;
    }
  }
  &-large {
    height: 650px;
    @media only screen and (max-width: 1023px) {
      height: 450px;
    }
    @media only screen and (max-width: 767px) {
      height: 250px;
    }
  }
}
