.counter {
  @apply text-dulux rounded-md border flex items-center;
  border-color: #ececec;
  max-width: 12rem;

  span {
    @apply w-16 h-16 flex items-center justify-center leading-none cursor-pointer text-xl;
  }

  input {
    @apply w-16 h-16 text-center border-l border-r;
    border-color: #ececec;
  }
}
