$font-stack: Inter var, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
  Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

.dulux-toast {
  @apply border-4 border-dulux text-sm text-dulux;
  font-family: $font-stack;
  .Toastify__close-button {
    @apply text-dulux opacity-100 self-center text-sm;
  }
  .Toastify__progress-bar {
    @apply bg-dulux;
    background: #001e45;
    height: 3px;
  }
}

.Toastify__toast-container {
  width: 100%;
  max-width: 600px;
}

.Toastify__toast-container--top-center {
  margin-left: 0;
  transform: translateX(-50%);
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-center {
    transform: translateX(0%);
  }
}
