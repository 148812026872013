@import "./fa/fontawesome";
@import "./fa/solid";

/**
If additional hubspot forms get added you'll likely want to scope the styles here
*/
.hs-form {
  @apply flex;

  label {
    @apply text-lg;
    &.hs-error-msg {
      @apply block text-red-500 mt-2 text-sm;
    }
    .hs-form-required {
      @apply hidden;
    }
  }

  .submitted-message {
    @apply text-center;
  }

  .hs-fieldtype-text {
    @apply w-full;
  }

  .hs-input {
    @apply rounded-none w-full h-12 py-2 text-white bg-dulux-med border-2 text-2xl;
    border: none;
    border-bottom: solid white 2px;
    &::placeholder {
      color: white;
    }
  }

  .hs-submit {
    // @apply bg-transparent text-center text-sm py-2 px-3 flex items-center w-32 h-12 justify-center transition-fade text-white outline-none cursor-pointer border-2;
    // border: none;
    // border-bottom: solid white;
    // &:hover {
    //   @apply text-black bg-white;
    //   .hs-button {
    //     @apply text-black;
    //   }
    // }
  }

  .actions {
    @extend %fa-icon;
    @extend .fas;
    position: relative;

    input[type="submit"],
    &:before {
      position: relative;
      font-size: 1.1rem;
      width: 60px;
      height: 60px;
      padding: 20px;
      margin-top: 20px;
      background-image: url(/icons/arrow-right-mini.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .hs_error_rollup {
    @apply hidden;
  }

  .hs-button {
    @apply bg-transparent text-center text-sm py-2 px-3 text-white outline-none cursor-pointer;
  }
}
