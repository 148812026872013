@media only screen and (max-width: 767px) {
  .product-header {
    color: #001e45 !important;
    background-color: white !important;
    padding-top: 1rem;
  }
  .product-header a {
    color: #001e45 !important;
  }
  .product-header svg {
    stroke: #001e45 !important;
  }
}
