.wysiwyg {
  h1 {
    @apply text-3xl pt-6;
  }
  h2 {
    @apply text-2xl pt-6;
  }
  h3,
  h4,
  h5,
  h6 {
    @apply text-lg pt-6 font-semibold;
  }
  p {
    @apply pt-2;
    a {
      text-decoration: underline;
    }
  }
  strong {
    font-weight: 600;
  }
  ol {
    @apply list-decimal;
  }
  ul {
    @apply list-disc;
    padding-left: 20px;
  }
}
